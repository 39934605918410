import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import {
  Modal,
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Stack,
} from "@mui/material";
import { imageDefaultStyleCover } from "../../theme/globalStyles";

const style = {
  position: "absolute",
  p: 5,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: "13px",
  "&:focus": { outline: "none" },
};

export default function ImageUpload(props) {
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [imageType, setImageType] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const imageTypeChangeHandler = (e) => {
    setImageType(e.target.value);
  };

  const uploadImage = async () => {
    const data = new FormData();
    data.append("fileType", props.imageType);
    data.append("file", imageFile[0]);
    data.append("fileUploadType", imageType);

    await props.submitImage(data);

    handleClose();
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          "&:focus": { outline: "none" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            border: "1px dashed #9E9E9E",
            "& .image": {
              display: "block",
              width: "100%",
              height: "auto",
            },
            "& .image-text": {
              position: "absolute",
              bottom: 0,
              background: "rgb(0,0,0,0.5)",
              // color: "#f1f1f1",
              width: "100%",
              transition: ".5s ease",
              opacity: 0,
              color: "white",
              fontSize: 20,
              paddding: 20,
              textAlign: "center",
            },
            "&:hover": {
              cursor: "pointer",
              "& .image-text": {
                opacity: 1,
              },
            },
            "&:focus": { outline: "none" },
          }}
          onClick={handleOpen}
        >
          <Box sx={{ height: "250px", width: "250px" }}>
            <img
              className="image"
              src={props.image}
              style={imageDefaultStyleCover}
            />
          </Box>
          <p className="image-text">
            {props.label ? props.label : "Upload Image"}
          </p>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" gutterBottom textAlign={"center"}>
            Upload Image
          </Typography>
          <ImageUploader
            withIcon={true}
            buttonText="Choose image"
            onChange={(e) => {
              setImageFile(e);
              if (e[0]?.name.length > 3) {
                setDisable(false);
              } else {
                setDisable(true);
              }
            }}
            imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
            maxFileSize={5242880}
            withPreview={true}
            singleImage={true}
            label="Max file size: 5mb, accepted: jpg ,jpeg ,gif ,png"
          />
          <FormControl fullWidth sx={{ my: 5 }}>
            <InputLabel id="demo-simple-select-label">
              Select Image Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select image Type"
              value={imageType}
              onChange={imageTypeChangeHandler}
            >
              <MenuItem value="USER_IMAGE">User Image</MenuItem>
              <MenuItem value="BLOG_IMAGE">Blog Image</MenuItem>
              <MenuItem value="POST_IMAGE">Post Image</MenuItem>BADGE_PHOTO
              <MenuItem value="BADGE_PHOTO">Badge Image</MenuItem>
              <MenuItem value="WALKTHROUGH">Walk Through</MenuItem>
              <MenuItem value="EVENT_PHOTO">Event Photo</MenuItem>
              <MenuItem value="SPONSOR_PHOTO">Sponsor Photo</MenuItem>
              <MenuItem value="MEMORY_PHOTO">Memory Photo</MenuItem>
              <MenuItem value="BOARD_MEMBER_PHOTO">Board Member Photo</MenuItem>
              <MenuItem value="PRODUCT_PHOTO">Product Photo</MenuItem>
            </Select>
          </FormControl>
          <Stack justifyContent={"center"} direction={"row"} gap={5}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={disable}
              variant="contained"
              onClick={uploadImage}
            >
              Upload
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
