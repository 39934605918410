import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
// import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
// import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
// import Pricing from "./pages/pages/Pricing";
// import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
// import Chat from "./pages/pages/Chat";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Landing
// import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

// admin
import AdminProfile from "./pages/admin/AdminProfile";
import AdminProfileUpdate from "./pages/admin/AdminProfileUpdate";
import AdminAll from "./pages/admin/AdminAll";
import AdminCreate from "./pages/admin/AdminCreate";

// role
import RoleAll from "./pages/role/RoleAll";
import RoleCreate from "./pages/role/RoleCreate";

// blog
import AllBlogs from "./pages/blogs/AllBlogs";
import CreateBlog from "./pages/blogs/CreateBlog";

// user
import ExcelImport from "./pages/pages/User/ExcelImport";
// import { important } from "polished";

// notification
import Notifications from "./pages/pages/Notification/Notifications";
import SendCustomNotification from "./pages/pages/Notification/SendCustomNotification";

// event address
import EventAddress from "./pages/pages/Events/EventAddress";
import AddEventAddress from "./pages/pages/Events/AddEventAddress";

import SpecificProductAttribute from "./pages/pages/Product/SpecificProductAttribute";
import EditSpecificProductAttribute from "./pages/pages/Product/EditSpecificProductAttribute";
import ProductSliders from "./pages/pages/Slider/ProductSlider";
import AddProductSlider from "./pages/pages/Slider/AddProductSlider";
import AlbumMemory from "./pages/pages/Memory/AlbumMemory";

const User = async(() => import("./pages/pages/User/User"));
const UserInfo = async(() => import("./pages/pages/User/UserInfo"));

// ranks
const Ranks = async(() => import("./pages/pages/Rank/Ranks"));
const AddRank = async(() => import("./pages/pages/Rank/AddRank"));

// board-members
const BoardMembers = async(() =>
  import("./pages/pages/board-member/BoardMembers")
);
const AddBoardMember = async(() =>
  import("./pages/pages/board-member/AddBoardMember")
);
const BoardMemberCategory = async(() =>
  import("./pages/pages/board-member/BoardMemberCategory")
);
const AddBoardMemberCategory = async(() =>
  import("./pages/pages/board-member/AddBoardMemberCategory")
);
const BoardMembersDelegatesCategories = async(() =>
  import("./pages/pages/board-member/delegates/DelegatesCategories")
);
const AddBoardMemberDelegatesCategory = async(() =>
  import("./pages/pages/board-member/delegates/AddDelegateCategory")
);
const UpdateDelegateCategory = async(() =>
  import("./pages/pages/board-member/delegates/UpdateDelegateCategory")
);
const BoardMembersDelegates = async(() =>
  import("./pages/pages/board-member/delegates/Delegates")
);
const AddDelegate = async(() =>
  import("./pages/pages/board-member/delegates/AddDelegate")
);
const UpdateDelegate = async(() =>
  import("./pages/pages/board-member/delegates/UpdateDelegate")
);

// blogPost
const BlogPosts = async(() => import("./pages/pages/Blogs/BlogPosts"));
const AddBlogPost = async(() => import("./pages/pages/Blogs/AddBlogPost"));

// events
const Events = async(() => import("./pages/pages/Events/Events"));
const AddEvents = async(() => import("./pages/pages/Events/AddEvents"));
const EventParticipants = async(() =>
  import("./pages/pages/Events/EventParticipants")
);

//links
const AllLinks = async(() => import("./pages/pages/links"));
const AddLink = async(() => import("./pages/pages/links/AddLink"));

// ticketCategory
const TicketCategory = async(() =>
  import("./pages/pages/TicketCategory/TicketCategory")
);
const AddTicketCategory = async(() =>
  import("./pages/pages/TicketCategory/AddTicketCategory")
);

// tickets
const Tickets = async(() => import("./pages/pages/Ticket/Tickets"));
const AddTicket = async(() => import("./pages/pages/Ticket/AddTicket"));

// products
const Products = async(() => import("./pages/pages/Product/Products"));
const AddProduct = async(() => import("./pages/pages/Product/AddProduct"));
const ProductCategory = async(() =>
  import("./pages/pages/Product/ProductCategory")
);
const AddProductCategory = async(() =>
  import("./pages/pages/Product/AddProductCategory")
);
const ProductAttribute = async(() =>
  import("./pages/pages/Product/ProductAttribute")
);
const AddProductAttribute = async(() =>
  import("./pages/pages/Product/AddProductAttribute")
);

// orders
const OrderList = async(() => import("./pages/pages/Order/Orders"));

// Sponsors
const Sponsors = async(() => import("./pages/pages/Sponsor/Sponsors"));
const AddSponsor = async(() => import("./pages/pages/Sponsor/AddSponsor"));

// Memory
const Album = async(() => import("./pages/pages/Memory/Album"));
const AddAlbum = async(() => import("./pages/pages/Memory/AddAlbum"));

const Memory = async(() => import("./pages/pages/Memory/Memory"));
const AddMemory = async(() => import("./pages/pages/Memory/AddMemory"));

// subscription
const Subscriptions = async(() =>
  import("./pages/pages/Subscription/Subscriptions")
);
const AddSubscription = async(() =>
  import("./pages/pages/Subscription/AddSubscription")
);
const SubscriptionPackages = async(() =>
  import("./pages/pages/Subscription/SubscriptionPackages")
);
const AddSubscriptionPackage = async(() =>
  import("./pages/pages/Subscription/AddSubscriptionPackage")
);

// slider
const Sliders = async(() => import("./pages/pages/Slider/Sliders"));
const AddSlider = async(() => import("./pages/pages/Slider/AddSlider"));

// configuration
const Configuration = async(() =>
  import("./pages/pages/Configuration/Configuration")
);

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
// const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
    ],
  },
  {
    path: "user",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <User />,
      },
      {
        path: ":id",
        element: <UserInfo />,
      },
      {
        path: "excel-import",
        element: <ExcelImport />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <AdminAll />,
      },
      {
        path: "add",
        element: <AdminCreate />,
      },
      {
        path: "profile",
        element: <AdminProfile />,
      },
      {
        path: "profile-update",
        element: <AdminProfileUpdate />,
      },
    ],
  },
  {
    path: "role",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <RoleAll />,
      },
      {
        path: "create",
        element: <RoleCreate />,
      },
    ],
  },
  {
    path: "Rank",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Ranks />,
      },
      {
        path: "all",
        element: <Ranks />,
      },
      {
        path: "add",
        element: <AddRank />,
      },
      {
        path: "edit/:id",
        element: <AddRank />,
      },
    ],
  },
  {
    path: "board-member",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <BoardMembers />,
      },
      {
        path: "add",
        element: <AddBoardMember />,
      },
      {
        path: "edit/:id",
        element: <AddBoardMember />,
      },
    ],
  },
  {
    path: "board-member-category",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <BoardMemberCategory />,
      },
      {
        path: "add",
        element: <AddBoardMemberCategory />,
      },
      {
        path: "edit/:id",
        element: <AddBoardMemberCategory />,
      },
    ],
  },
  {
    path: "board-member-delegates",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <BoardMembersDelegates />,
      },
      {
        path: "add",
        element: <AddDelegate />,
      },
      {
        path: "edit/:id",
        element: <UpdateDelegate />,
      },
    ],
  },
  {
    path: "board-member-delegates-category",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <BoardMembersDelegatesCategories />,
      },
      {
        path: "add",
        element: <AddBoardMemberDelegatesCategory />,
      },
      {
        path: "edit/:id",
        element: <UpdateDelegateCategory />,
      },
    ],
  },
  {
    path: "blog",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <AllBlogs />,
      },
      {
        path: "create",
        element: <CreateBlog />,
      },
      {
        path: "edit/:id",
        element: <CreateBlog />,
      },
      {
        path: "posts",
        element: <BlogPosts />,
      },
      {
        path: ":blogUUID/posts/add",
        element: <AddBlogPost />,
      },
      {
        path: ":blogUUID/posts/edit/:id",
        element: <AddBlogPost />,
      },
    ],
  },
  {
    path: "event",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Events />,
      },
      {
        path: "all",
        element: <Events />,
      },
      {
        path: "add",
        element: <AddEvents />,
      },
      {
        path: "edit/:id",
        element: <AddEvents />,
      },
      {
        path: ":id/participants",
        element: <EventParticipants />,
      },
      {
        path: "address",
        element: <EventAddress />,
      },
      {
        path: "address/all",
        element: <EventAddress />,
      },
      {
        path: "address/add",
        element: <AddEventAddress />,
      },
      {
        path: "address/edit/:id",
        element: <AddEventAddress />,
      },
      {
        path: "ticket-category/all",
        element: <TicketCategory />,
      },
      {
        path: "ticket-category/add",
        element: <AddTicketCategory />,
      },
      {
        path: "ticket-category/edit/:id",
        element: <AddTicketCategory />,
      },
      {
        path: "ticket/all",
        element: <Tickets />,
      },
      {
        path: "ticket/add",
        element: <AddTicket />,
      },
    ],
  },
  {
    path: "links",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AllLinks />,
      },
      {
        path: "add",
        element: <AddLink />,
      },
      {
        path: "edit/:id",
        element: <AddLink />,
      },
    ],
  },

  {
    path: "product",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Products />,
      },
      {
        path: "all",
        element: <Products />,
      },
      {
        path: "add",
        element: <AddProduct />,
      },
      {
        path: "edit/:id",
        element: <AddProduct />,
      },
      {
        path: ":id/attribute",
        element: <SpecificProductAttribute />,
      },
      {
        path: ":id/attribute/:productAttributeId/edit",
        element: <EditSpecificProductAttribute />,
      },
    ],
  },

  {
    path: "order",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrderList />,
      },
      {
        path: "all",
        element: <OrderList />,
      },
    ],
  },

  {
    path: "product-attribute",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <ProductAttribute />,
      },
      {
        path: "add",
        element: <AddProductAttribute />,
      },
      {
        path: "edit/:id",
        element: <AddProductAttribute />,
      },
    ],
  },

  {
    path: "product-category",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "all",
        element: <ProductCategory />,
      },
      {
        path: "add",
        element: <AddProductCategory />,
      },
      {
        path: "edit/:id",
        element: <AddProductCategory />,
      },
    ],
  },

  {
    path: "sponsor",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Sponsors />,
      },
      {
        path: "all",
        element: <Sponsors />,
      },
      {
        path: "add",
        element: <AddSponsor />,
      },
      {
        path: "edit/:id",
        element: <AddSponsor />,
      },
    ],
  },

  {
    path: "album",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Album />,
      },
      {
        path: "all",
        element: <Album />,
      },
      {
        path: "add",
        element: <AddAlbum />,
      },
      {
        path: "edit/:id",
        element: <AddAlbum />,
      },
      {
        path: "memory/:id",
        element: <AlbumMemory />,
      },
    ],
  },

  {
    path: "memory",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Memory />,
      },
      {
        path: "all",
        element: <Memory />,
      },
      {
        path: "add",
        element: <AddMemory />,
      },
      {
        path: "edit/:id",
        element: <AddMemory />,
      },
    ],
  },

  {
    path: "subscription",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Subscriptions />,
      },
      {
        path: "all",
        element: <Subscriptions />,
      },
      {
        path: "add",
        element: <AddSubscription />,
      },
      {
        path: "edit/:id",
        element: <AddSubscription />,
      },
    ],
  },

  {
    path: "subscription-package",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SubscriptionPackages />,
      },
      {
        path: "all",
        element: <SubscriptionPackages />,
      },
      {
        path: "add",
        element: <AddSubscriptionPackage />,
      },
      {
        path: "edit/:id",
        element: <AddSubscriptionPackage />,
      },
    ],
  },

  {
    path: "slider",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Sliders />,
      },
      {
        path: "all",
        element: <Sliders />,
      },
      {
        path: "add",
        element: <AddSlider />,
      },
      {
        path: "edit/:id",
        element: <AddSlider />,
      },
    ],
  },

  {
    path: "product-slider",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductSliders />,
      },
      {
        path: "all",
        element: <ProductSliders />,
      },
      {
        path: "add",
        element: <AddProductSlider />,
      },
      {
        path: "edit/:id",
        element: <AddProductSlider />,
      },
    ],
  },

  {
    path: "configuration",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Configuration />,
      },
      {
        path: "all",
        element: <Configuration />,
      },
    ],
  },

  {
    path: "notification",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Notifications />,
      },
      {
        path: "all",
        element: <Notifications />,
      },
      {
        path: "send",
        element: <SendCustomNotification />,
      },
    ],
  },

  {
    path: "projects",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },

  {
    path: "invoices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },

  {
    path: "orders",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },

  {
    path: "tasks",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },

  {
    path: "calendar",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "components",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "accordion",
        element: <Accordion />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "chips",
        element: <Chips />,
      },
      {
        path: "dialogs",
        element: <Dialogs />,
      },
      {
        path: "lists",
        element: <Lists />,
      },
      {
        path: "menus",
        element: <Menus />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "snackbars",
        element: <Snackbars />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
    ],
  },
  {
    path: "forms",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "simple-table",
        element: <SimpleTable />,
      },
      {
        path: "advanced-table",
        element: <AdvancedTable />,
      },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "icons",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Chartjs />,
      },
    ],
  },
  {
    path: "maps",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "migrating-to-next-js",
        element: <MigratingToNextJS />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
