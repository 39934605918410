import { Briefcase, CreditCard, Layout, Link, Sliders } from "react-feather";
import PanoramaIcon from "@mui/icons-material/Panorama";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Dashboard, MilitaryTech, Star } from "@mui/icons-material";

const DashboardSection = [
  {
    href: "/",
    icon: Dashboard,
    title: "Dashboard",
  },
];

const usersSection = [
  {
    href: "/user",
    icon: AccountCircleIcon,
    title: "User Management",
    children: [
      {
        icon: AdminPanelSettingsIcon,
        href: "/admin/all",
        title: "Admins",
      },
      {
        icon: AccountCircleIcon,
        href: "/user/all",
        title: "Users",
      },
    ],
  },
  {
    href: "/role",
    icon: VerifiedUserIcon,
    title: "Role Management",
    children: [
      {
        href: "/role/all",
        title: "Manage Roles",
      },
    ],
  },
  {
    href: "/rank",
    icon: Star,
    title: "Rank & Borough",
    children: [
      {
        href: "/rank/all",
        title: "Manage Rank",
        icon: MilitaryTech,
      },
    ],
  },
];

const boardMemberSection = [
  {
    href: "/board-member",
    icon: PersonPinIcon,
    title: "Board Management",
    children: [
      {
        href: "/board-member/all",
        title: "Manage Board Members",
      },
      {
        href: "/board-member-category/all",
        title: "Manage Categories",
      },
      {
        href: "/board-member-delegates/all",
        title: "Manage Delegates",
      },
      {
        href: "/board-member-delegates-category/all",
        title: "Manage Delegates Categories",
      },
    ],
  },
];

const blogSection = [
  {
    href: "/blog",
    icon: Layout,
    title: "Blog Management",
    children: [
      {
        href: "/blog/all",
        title: "Manage Blogs",
      },
      {
        href: "/blog/posts",
        title: "Manage Posts",
      },
    ],
  },
];

const eventSection = [
  {
    href: "/events",
    icon: Sliders,
    title: "Event Management",
    children: [
      {
        href: "/event/all",
        title: "Manage Events",
      },
      {
        href: "/event/ticket/all",
        title: "Manage Tickets",
      },
      {
        href: "/event/ticket-category/all",
        title: "Manage Ticket Categories",
      },
    ],
  },
];

const linksSection = [
  {
    href: "/links",
    icon: Link,
    title: "Link Management",
  },
];

// const productSection = [
//   {
//     href: "/product",
//     icon: ShoppingBag,
//     title: "Shop Management",
//     children: [
//       {
//         href: "/product-category/all",
//         title: "Manage Product Categories",
//       },
//       {
//         href: "/product/all",
//         title: "Manage Product",
//       },
//       {
//         href: "/order/all",
//         title: "Manage Orders",
//       },
//       {
//         href: "/product-slider/all",
//         title: "Manage Product Sliders",
//       },
//     ],
//   },
// ];

const memorySection = [
  {
    href: "/memory",
    icon: PhotoCameraBackIcon,
    title: "Memory Management",
    children: [
      {
        href: "/album/all",
        title: "Manage Albums",
      },
      {
        href: "/memory/all",
        title: "Manage Memory",
      },
    ],
  },
];

const subscribeSection = [
  {
    href: "/subscription",
    icon: CreditCard,
    title: "Subscription Management",
    children: [
      {
        href: "/subscription/all",
        title: "Manage Subscription",
      },
      {
        href: "/subscription-package/all",
        title: "Manage Subscription Package",
      },
    ],
  },
];

const sponsorSection = [
  {
    href: "/sponsor",
    icon: Briefcase,
    title: "Sponsor Management",
    children: [
      {
        href: "/sponsor/all",
        title: "Manage Sponsors",
      },
    ],
  },
];

const sliderSection = [
  {
    href: "/slider",
    icon: PanoramaIcon,
    title: "Home Slider Management",
    children: [
      {
        href: "/slider/all",
        title: "Manage Sliders",
      },
    ],
  },
];

const configSection = [
  {
    href: "/configuration",
    icon: SettingsIcon,
    title: "Configuration",
    children: [
      {
        href: "/configuration/all",
        title: "Manage Configuration",
      },
    ],
  },
  {
    href: "/notification",
    icon: NotificationsActiveIcon,
    title: "Notification Center",
    children: [
      {
        href: "/notification/all",
        title: "Notification Management",
      },
    ],
  },
];

const navItems = [
  {
    title: "Dashboard",
    pages: DashboardSection,
  },
  {
    title: "Users",
    pages: usersSection,
  },
  {
    title: "Board-Members",
    pages: boardMemberSection,
  },
  {
    title: "Blogs",
    pages: blogSection,
  },
  {
    title: "Events",
    pages: eventSection,
  },
  {
    title: "Links",
    pages: linksSection,
  },
  // {
  //   title: "Shops",
  //   pages: productSection,
  // },
  {
    title: "Memories",
    pages: memorySection,
  },
  {
    title: "Subscriptions",
    pages: subscribeSection,
  },
  {
    title: "Sponsors",
    pages: sponsorSection,
  },
  {
    title: "Sliders",
    pages: sliderSection,
  },
  {
    title: "Configuration",
    pages: configSection,
  },
];

export default navItems;
