import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Stack,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";
import {
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  activeIndicateColor,
  inActiveIndicateColor,
  imageDefaultStyleCover,
} from "../../theme/globalStyles";
import ViewSlider from "../modals/ViewSlider";

export default function SliderTable(props) {
  const navigate = useNavigate();

  const { allSlider, deleteSlider } = props;
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px",
          },
          // "table, td, th": {
          //   borderRight: "1px solid #d9dede",
          //   "&:last-of-type": {
          //     borderRight: "none",
          //   },
          // },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Message</TableCell>
            <TableCell align="left">Order</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allSlider?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <Box
                  sx={{
                    height: "100px",
                    width: "150px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={row?.imageFile?.fileUrl ?? "/default-image.png"}
                    alt={row?.imageFile?.fileName ?? row?.name}
                    style={{
                      ...imageDefaultStyleCover,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell align="left">{row.title}</TableCell>
              <TableCell align="left">{row.message}</TableCell>
              <TableCell align="left">{row.order}</TableCell>
              <TableCell align="left">
                <Stack direction="row" gap={1} alignItems="center">
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "8px",
                      backgroundColor: row.active
                        ? activeIndicateColor
                        : inActiveIndicateColor,
                    }}
                  ></div>
                  {row.active ? "Active" : "Inactive"}
                </Stack>
              </TableCell>

              <TableCell align="left">
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  <ViewSlider id={row.id} />

                  <Tooltip title="Edit Slider" arrow>
                    <IconButton
                      color="primary"
                      variant="outlined"
                      sx={{ ...sxTableActionButtonBorder }}
                      onClick={() => navigate(`/slider/edit/${row.id}`)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete Slider" arrow>
                    <IconButton
                      color="error"
                      sx={{ ...sxTableActionButtonBorderRed }}
                      onClick={() => deleteSlider(row.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
